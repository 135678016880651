 <template>
  <v-container fluid fill-height class="pa-0">
    <headerToolbar color="header_dark_blue" dark>
      <template v-slot:toolbarTitle>
        <router-link to="/">
          <img
            alt="GetCollective"

            src="/images/logo_menu_white_nav.png"
          />
        </router-link>
      </template>
    </headerToolbar>
    <v-container class="text-center">
      <span class="page_not_found_404 mid_grey--text font-weight-bold"
        >404</span
      >
      <p class="caption">{{ $t("misc.404") }}</p>
      <v-btn
        :to="'/'"
        color="button_blue"
        depressed
        rounded
        class="white--text mt-8"
      >
        {{ $t("misc.returnToMeetingList") }}
      </v-btn>
    </v-container>
  </v-container>
</template>

<script>
import headerToolbar from "@/components/shared/headerToolbar";
export default {
  name: "page_not_found",
  mixins: [],
  components: { headerToolbar },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
  beforeDestroy() {},
  created() {
    document.title = this.$t("misc.pageNotFound");
  },
};
</script>

<style scoped>
.page_not_found_404 {
  font-size: max(60%, 10em);
  line-height: 1em;
}
</style>

